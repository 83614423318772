import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"

import {
  LyneupContainer,
  ErrorBox,
  LyneupUserSection,
  LyneUpSectionBgImage,
  LyneupUserTextBox,
  LyneUpTextContainer,
  LyneupUserLogoImage,
  LyneupSectionTitle,
  LyneupSectionText,
  LyneupBenefitsSection,
  LyneupBenefitsBox,
  LyneupBenefitsImage,
  LyneupBenefitsText,
  LyneupExpertSection,
  LyneupCircleSection,
  LyneupDesktopSection,
  LyneupDesktopImageSection,
  LyneupDesktopImage,
  LyneupDesktopTextImage,
  LyneupDesktopTextSection,
  LyneupSpineCircleContainer,
  LyneupDesktopSpineCircleSection,
  LyneupSpineCircleImage,
  EnterPriseSectionRedirect,
  LyneUpLandingTopButton,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  LyneupSizeErrorBox,
  LyneupSectionSubTitle,
  ProductShopSection,
  LyneShopImageSection,
  LyneShopInfoSection,
  LyneupAddToCart,
  LyneAddToCartWrapper,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductAlmaDetail,
  LyneNoSizeText,
} from "../../../components/Products/styled"

import { ProductSizeVariationBoxWrapper } from "../../../components/Products/ProductSizeVariationBox"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import {
  CapitalizeFirstLetter,
  colors,
  genders,
  genderReverse,
  LyneUpNewImageSliders,
  LyneUpComboImage,
  colorsReverse,
} from "../../../utils/lyneUphelpers"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { navigate } from "@reach/router"
import PriceDetails from "../../../components/Products/PriceDetails"
import LyneupSizeGuidePopup from "../../../components/Products/LyneupSizeGuidePopup"
import LyneStickyAddToCart from "../../../components/Products/LyneStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addLyneHomeUrl,
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import LyneHomeCrossProductSection from "../../../components/Products/LyneHomeCrossProductSection"
import {
  SizeSelectorLyneUpFemale,
  SizeSelectorLyneUpMale,
} from "../../../components/SizeSelector"
import ResultHomeSection from "../../../components/Layout/ResultHomeSection"
import LyneHomeInnovationSection from "../../../components/Products/LyneHomeInnovationSection"
import TvAdsSection from "../../../components/Layout/TvAdsSection"
import TestimonialHomeSection from "../../../components/Layout/TestimonialHomeSection"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import {
  getPrice,
  monthlyPrice,
  navigateToCart,
} from "../../../utils/navigateToCart"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"
import LyneProductTopSection from "../../../components/Products/LyneProductTopSection"
import LyneImageSlider from "../../../components/Products/LyneImageSlider"
import LyneupGenderSection from "../../../components/Products/LyneupGenderSection"
import LyneColorSection from "../../../components/Products/LyneColorSection"
import LyneTestimonialSection from "../../../components/Products/LyneTestimonialSection"
import LyneComboSection from "../../../components/Products/LyneComboSection"
import LyneupSizeSelectorSection from "../../../components/Products/LyneupSizeSelectorSection"
import LyneImageSection from "../../../components/Products/LyneImageSection"
import LyneupSizeGuideModal from "../../../components/Products/LyneupSizeGuideModal"

const LyneUp = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lyneup"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({
    Gender: "",
    Color: "",
    Size: "",
  })
  const [openModal, setopenModal] = useState(false)
  const [openSizeModal, setopenSizeModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [activeFlag, setactiveFlag] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [menSizeParameters, setmenSizeParameters] = useState({
    Age: "",
    Height: "",
    Weight: "",
  })

  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [activeGenderIndex, setactiveGenderIndex] = useState(null)
  const [activeColorIndex, setactiveColorIndex] = useState(null)
  const [recommendation, setRecommendation] = useState({})
  const [selectOfferType, setSelectOfferType] = useState("Seul")
  const [productPrice, setProductPrice] = useState(129)

  const handleQuantityChange = event => {
    setselectedVariantQuantity(event.target.value)
  }

  const handleParamStrings = (selectedOptions, offerType) => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    if (offerType) {
      paramString = paramString + "&Offer" + "=" + offerType
    }
    isBrowser && history.replaceState(null, null, paramString)
  }

  const removeErrors = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      let selectedOptions = { ...selectOptions }

      if (data.recommendation.size?.length) {
        selectedOptions["Size"] = data.recommendation.size
        setselectOptions(selectedOptions)

        handleChangeSelectOptions({
          target: { name: "Size", value: data.recommendation.size },
        })

        setRecommendation(data)

        if (selectOptions["Gender"] === "Male") {
          setmenSizeParameters(data)
        }

        seterrorSize("")
      } else {
        seterrorSize(
          intl
            .formatMessage({
              id: "size_selector.lyneup_size_error_message",
            })
            .replace(
              "/shop/",
              addLyneHomeUrl(intl.locale, selectOptions["Gender"])
            )
        )
      }
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    setselectOptions(selectOptions => {
      let selectedOptions = { ...selectOptions }

      if (target.name === "Color") {
        selectedOptions[target.name] = colors[intl.locale][target.value]
      } else if (target.name === "Gender") {
        selectedOptions[target.name] = genders[intl.locale][target.value]
        seterrorSize("")
        selectedOptions["Size"] = ""
        if (!selectOptions["Color"]) {
          selectedOptions["Color"] = "BLACK"
        }
      } else if (target.name === "Size") {
        if (target.value) {
          selectedOptions[target.name] = target.value
        } else {
          selectedOptions[target.name] = ""
        }
      }

      removeErrors(selectedOptions)
      handleParamStrings(selectedOptions, selectOfferType)

      if (Object.keys(selectedOptions).length === 3) {
        const selectedVariant = client.product.helpers.variantForOptions(
          product,
          selectedOptions
        )
        if (selectedVariant) {
          setselectedVariant(selectedVariant)
          setErrors({ exist: false, message: [] })
        } else setselectedVariant(null)
      }
      return selectedOptions
    })
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "size_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error" }),
        ]
    }

    removeErrors(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    let flatInput = recommendation?.inputs
      ? Object.keys(recommendation.inputs).map(key => ({
          key: key,
          value: `${recommendation.inputs[key]}`,
        }))
      : []

    let flatRecommendation = recommendation?.recommendation
      ? Object.keys(recommendation.recommendation).map(key => ({
          key: key,
          value: `${recommendation.recommendation[key]}`,
        }))
      : []
    // prepare attributes
    let attributes = [...flatInput, ...flatRecommendation]

    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        attributes,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    const offerType = urlParams.get("Offer")
    let options = { ...selectOptions }
    if (genderParam) {
      options.Gender = genderParam
      options.Color = "BLACK"
      setactiveGenderIndex(options.Gender)
      setactiveColorIndex(options.Color)
    }

    if (colorParam) {
      options.Color = colorParam
      setactiveColorIndex(options.Color)
    }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    if (offerType) {
      let price = ""
      if (offerType === "Pack") {
        price = lyneGroupDiscountPrice()
        setselectedVariantQuantity(2)
      } else {
        price = lyneDiscountPrice()
        setselectedVariantQuantity(1)
      }

      setProductPrice(price)
      setSelectOfferType(offerType)
    }
    setselectOptions(options)
  }

  const handleScrolling = () => {
    $(".sticy-lyneup-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        if (!selectOptions["Gender"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_gender_section").offset().top - 150,
            },
            1500
          )
        } else if (!selectOptions["Size"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_size_section").offset().top - 150,
            },
            1500
          )
        }
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const returnSizeSelector = mobile => {
    return (
      <>
        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Male"}
          mobile={mobile}
          style={{ margn: "10px 0px" }}
        >
          <SizeSelectorLyneUpMale
            setLoading={setLoading}
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Female"}
          mobile={mobile}
          style={{ margn: "10px 0px" }}
        >
          <SizeSelectorLyneUpFemale
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        {/* {errorSize && (
          <LyneupSizeErrorBox dangerouslySetInnerHTML={{ __html: errorSize }} />
        )} */}
      </>
    )
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const handleOfferType = offerType => {
    let price = ""
    if (offerType === "Pack") {
      price = lyneGroupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = lyneDiscountPrice()
      setselectedVariantQuantity(1)
    }

    let selectedOptions = { ...selectOptions }
    handleParamStrings(selectedOptions, offerType)

    setProductPrice(price)

    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
    setSelectOfferType(offerType)
  }

  let discountPercentage = 0.2

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // lyneDiscountPrice = Number(lyneDiscountPrice) - 15
    } else {
      // lyneDiscountPrice =
      //   Number(lyneDiscountPrice) - Number(lyneDiscountPrice) * Number(discountPercentage)
    }

    return getPrice(lyneDiscountPrice)
  }

  const lyneGroupPrice = () => {
    let lyneGroupPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    lyneGroupPrice = Number(lyneGroupPrice) * 2

    return getPrice(lyneGroupPrice)
  }

  const lyneGroupDiscountPrice = () => {
    let LyneGroupDiscountPrice = lyneGroupPrice()

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 50
      discountPercentage = 0.125
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
    } else {
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 60
    }

    return getPrice(LyneGroupDiscountPrice)
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    if (intl.locale === "de" || intl.locale === "es")
      navigate(`/${intl.locale}/shop/lyneup-2b/`)
    if (intl.locale === "en") navigate(`/${intl.locale}/shop/lyneup-test/`)
  }, [intl.locale])

  return (
    (intl.locale === "fr" || intl.locale === "en") &&
    data &&
    data.allDirectusLyneupTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="lyneup"
          hreflangPathname={{
            fr: `shop/lyneup`,
            en: `shop/lyneup-test`,
            de: `shop/lyneup-2b`,
            es: `shop/lyneup-2b`,
          }}
        />
        <LyneStickyAddToCart
          gender={selectOptions["Gender"]}
          color={selectOptions["Color"]}
          size={selectOptions["Size"]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_size",
                })
              : data.allDirectusLyneupTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          regularPrice={
            selectedVariantQuantity == 1
              ? Number(
                  selectedVariant
                    ? selectedVariant.price
                    : product.priceRangeV2.maxVariantPrice.amount
                ).toFixed(0)
              : lyneGroupPrice()
          }
          discountPrice={
            selectedVariantQuantity == 1
              ? lyneDiscountPrice()
              : lyneGroupDiscountPrice()
          }
          quantity={selectedVariantQuantity}
        />
        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />
        {!IsMobile ? (
          <LyneupSizeGuidePopup
            data={data.allDirectusLyneupTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            gender={selectOptions["Gender"]}
            open={openSizeModal}
            closeHandle={setopenSizeModal}
          />
        ) : (
          <LyneupSizeGuideModal
            data={data.allDirectusLyneupNewPageTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            size_data={data.allDirectusLyneupTranslation.nodes[0].size}
            color_data={data.allDirectusLyneupTranslation.nodes[0].color}
            gender={selectOptions["Gender"]}
            open={openSizeModal}
            closeHandle={setopenSizeModal}
            addToCart={handleAddTocart}
            handleSelectOptions={handleChangeSelectOptions}
            addToCartText={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_add_to_cart_button
            }
            size={selectOptions["Size"]}
            color={colorsReverse[intl.locale][selectOptions["Color"]]}
            formatText={CapitalizeFirstLetter}
            quantity={selectedVariantQuantity}
            quantitySelector={setselectedVariantQuantity}
            handleQuantity={handleQuantityChange}
            variant={selectedVariant}
            material_info={
              data.allDirectusLyneupTranslation.nodes[0].product_material_info
            }
            setopenSizeModal={setopenSizeModal}
            selectOptions={selectOptions}
            Errors={Errors}
            returnSizeSelector={returnSizeSelector}
            one_size_system_text={
              data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
            }
            morphology_text={
              data.allDirectusLyneupTranslation.nodes[0].morphology_text
            }
            i_calculate_text={
              data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
            }
            most_suited_text={
              data.allDirectusLyneupTranslation.nodes[0].most_suited_text
            }
            edit_my_information_text={
              data.allDirectusLyneupTranslation.nodes[0]
                .edit_my_information_text
            }
            return_text={data.allDirectusLyneupTranslation.nodes[0].return_text}
            menSizeOptions={menSizeParameters}
            menerrorSize={errorSize}
            setmenerrorSize={seterrorSize}
            price={Number(
              selectedVariant
                ? selectedVariant.price
                : product.priceRangeV2.maxVariantPrice.amount
            ).toFixed(0)}
            selectedVariantQuantity={setselectedVariantQuantity}
            estimateDeliveryText={
              data.allDirectusCommonTranslation.nodes[0].months_text
            }
            loading={storeContext.adding ? "adding" : ""}
          />
        )}
        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <LyneupContainer>
          <ProductShopSection>
            <LyneShopImageSection>
              <LyneImageSection
                data={LyneUpNewImageSliders}
                gender={selectOptions["Gender"]}
                color={selectOptions["Color"]}
                medicaleLogo={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .innovation_section.medical_image
                }
              />
            </LyneShopImageSection>
            <LyneShopInfoSection>
              <LyneProductTopSection
                regularPrice={Number(
                  selectedVariant
                    ? selectedVariant.price
                    : product.priceRangeV2.maxVariantPrice.amount
                ).toFixed(0)}
                discountPrice={lyneDiscountPrice()}
                title={
                  data.allDirectusLyneup2BPageTranslation.nodes[0].lyneup_title
                }
                openAlma={setopenAlmaModal}
              />

              <LyneupGenderSection
                data={data.allDirectusLyneupTranslation.nodes[0].gender.item}
                handleChange={handleChangeSelectOptions}
                gender={selectOptions["Gender"]}
                error={selectOptions["Gender"] ? null : Errors.exist}
                id="lyne_gender_section"
              />

              {selectOptions["Gender"] && selectOptions["Color"] && (
                <Fragment>
                  <LyneColorSection
                    data={data.allDirectusLyneupTranslation.nodes[0].color.item}
                    handleChange={handleChangeSelectOptions}
                    disabled={!(selectOptions && selectOptions["Gender"])}
                    selectedColor={selectOptions["Color"]}
                  />

                  {selectOptions["Gender"] && selectOptions["Color"] && (
                    <LyneImageSlider
                      data={
                        selectOptions["Gender"] && selectOptions["Color"]
                          ? LyneUpNewImageSliders[selectOptions["Gender"]][
                              selectOptions["Color"]
                            ]
                          : data.allDirectusLyneupTranslation.nodes[0]
                              .product_images_section.men.noir
                      }
                    />
                  )}

                  <LyneupSizeSelectorSection
                    data={data.allDirectusLyneupTranslation.nodes[0].size}
                    size={selectOptions["Size"]}
                    openSizeModal={setopenSizeModal}
                    returnSizeSelector={returnSizeSelector}
                    handleChange={handleChangeSelectOptions}
                    error={!selectOptions["Size"] && Errors.exist}
                    errorSize={errorSize}
                    id="lyne_size_section"
                  />

                  {selectOptions["Gender"] &&
                    selectOptions["Color"] &&
                    data.allDirectusLyneupTranslation.nodes[0]
                      .combo_section && (
                      <LyneComboSection
                        data={
                          data.allDirectusLyneupTranslation.nodes[0]
                            .combo_section
                        }
                        images={LyneUpComboImage}
                        gender={selectOptions["Gender"]}
                        color={selectOptions["Color"]}
                        size={selectOptions["Size"]}
                        error={!selectOptions["Size"] && Errors.exist}
                        offerType={selectOfferType}
                        handle={handleOfferType}
                        regularPrice={
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        }
                        discountPrice={lyneDiscountPrice()}
                        bundleRegularPrice={lyneGroupPrice()}
                        bundleDiscountPrice={lyneGroupDiscountPrice()}
                      />
                    )}
                </Fragment>
              )}

              <div>
                {!selectOptions["Size"] && Errors.exist && (
                  <LyneNoSizeText
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "size_not_seleceted_error",
                      }),
                    }}
                    error={!selectOptions["Size"] && Errors.exist}
                    style={{ margin: "0px 0px 10px 0px" }}
                  />
                )}
                <LyneAddToCartWrapper>
                  <LyneupAddToCart
                    id="add_to_cart"
                    loading={storeContext.adding ? "adding" : ""}
                    onClick={() => handleAddTocart()}
                  >
                    {
                      data.allDirectusLyneupTranslation.nodes[0]
                        .product_add_to_cart_button
                    }
                  </LyneupAddToCart>
                </LyneAddToCartWrapper>

                {productPrice && (
                  <ProductAlmaPriceWrapper style={{ flexDirection: "row" }}>
                    <ProductAlmaPrice
                      style={{ marginRight: "5px", fontSize: "16px" }}
                    >
                      aussi payable en 3 x{" "}
                      <span>
                        {monthlyPrice(
                          productPrice
                            ? productPrice
                            : Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)
                        )}
                      </span>
                    </ProductAlmaPrice>
                    <ProductAlmaDetail
                      onClick={() => setopenAlmaModal(!openAlmaModal)}
                    >
                      {intl.formatMessage({
                        id: "alma_details_text",
                      })}
                    </ProductAlmaDetail>
                  </ProductAlmaPriceWrapper>
                )}

                {selectOptions["Size"] && (
                  <ProductEstimateShippingBox>
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "LyneUp",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale,
                        selectOptions["Size"],
                        selectOptions["Gender"],
                        selectOptions["Color"]
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
              </div>

              {data.allDirectusCommonTranslation.nodes[0]
                .lyne_testimonial_section && (
                <LyneTestimonialSection
                  data={
                    data.allDirectusCommonTranslation.nodes[0]
                      .lyne_testimonial_section
                  }
                  gender={selectOptions["Gender"] ? true : false}
                />
              )}
            </LyneShopInfoSection>
          </ProductShopSection>
        </LyneupContainer>

        {/* {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusCommonTranslation.nodes[0]
            .new_testimonial_section && (
            <TestimonialSection
              data={
                data.allDirectusCommonTranslation.nodes[0]
                  .new_testimonial_section
              }
            />
          )} */}

        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section && (
            <LyneupUserSection>
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0].back_section
                    .mobile_image
                }
              />
              <LyneUpTextContainer>
                <LyneupUserTextBox>
                  <LyneupSectionSubTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  />
                </LyneupUserTextBox>
              </LyneUpTextContainer>
            </LyneupUserSection>
          )}
        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section
            .list && (
            <LyneupBenefitsSection>
              {data.allDirectusLyneup2BPageTranslation.nodes[0].back_section.list.map(
                (item, index) => {
                  return (
                    <LyneupBenefitsBox key={index.toString()}>
                      {item.image && (
                        <LyneupBenefitsImage
                          className="lazyload"
                          data-src={item.image}
                        />
                      )}
                      {item.title && (
                        <LyneupBenefitsText
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        />
                      )}
                    </LyneupBenefitsBox>
                  )
                }
              )}
            </LyneupBenefitsSection>
          )}
        {!IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section && (
            <LyneupDesktopSection>
              <LyneupDesktopTextSection>
                <LyneupUserTextBox>
                  <LyneupUserLogoImage
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/21f9ad30-2a66-47b1-be80-548095d8b34d.svg"
                  />
                  <LyneupSectionSubTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  />
                  {/* <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  /> */}
                  {data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .back_section &&
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .back_section.list && (
                      <LyneupBenefitsSection>
                        {data.allDirectusLyneup2BPageTranslation.nodes[0].back_section.list.map(
                          (item, index) => {
                            return (
                              <LyneupBenefitsBox key={index.toString()}>
                                {item.image && (
                                  <LyneupBenefitsImage
                                    className="lazyload"
                                    data-src={item.image}
                                  />
                                )}
                                {item.title && (
                                  <LyneupBenefitsText
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  />
                                )}
                              </LyneupBenefitsBox>
                            )
                          }
                        )}
                      </LyneupBenefitsSection>
                    )}
                </LyneupUserTextBox>
              </LyneupDesktopTextSection>
              <LyneupDesktopImageSection top="100px">
                <LyneupDesktopImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .back_section.desktop_image
                  }
                />
              </LyneupDesktopImageSection>
            </LyneupDesktopSection>
          )}
        {!IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].spine_section &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].circle_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupSpineCircleContainer>
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .circle_section && (
                  <LyneupDesktopSpineCircleSection>
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .circle_section.title,
                      }}
                      style={{ marginBottom: "30px" }}
                    />
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .circle_section.desktop_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .circle_section.desktop_image
                        }
                        style={{ maxWidth: "900px" }}
                        alt={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.alt &&
                          intl.formatMessage({
                            id:
                              data.allDirectusLyneup2BPageTranslation.nodes[0]
                                .action_section.alt,
                          })
                        }
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}

        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].circle_section && (
            <LyneupCircleSection>
              <LyneupSectionTitle
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .circle_section.title,
                }}
                style={{ marginBottom: "30px" }}
              />
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .circle_section.mobile_image
                }
              />
            </LyneupCircleSection>
          )}
        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].action_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupSpineCircleContainer>
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .action_section && (
                  <LyneupDesktopSpineCircleSection
                    style={{ alignItems: "flex-start" }}
                  >
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.title,
                      }}
                    />

                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .action_section.mobile_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.mobile_image
                        }
                        style={{
                          maxWidth: "1080px",
                          width: "calc(100% + 40px)",
                          marginLeft: "-20px",
                        }}
                      />
                    )}
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .landing_button_text && (
                      <LyneUpLandingTopButton
                        className="sticy-lyneup-button"
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneup2BPageTranslation.nodes[0]
                              .landing_button_text,
                        }}
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}
        {!IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].action_section && (
            <LyneupDesktopSection style={{ background: "#A3C5DF" }}>
              <LyneupSpineCircleContainer>
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .action_section && (
                  <LyneupDesktopSpineCircleSection>
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.title,
                      }}
                    />
                    <LyneupSectionText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.text,
                      }}
                      style={{
                        maxWidth: "100%",
                        margin: "0px 0px 20px",
                        fontSize: "18px",
                      }}
                    />
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .action_section.desktop_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.desktop_image
                        }
                        alt={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.alt &&
                          intl.formatMessage({
                            id:
                              data.allDirectusLyneup2BPageTranslation.nodes[0]
                                .action_section.alt,
                          })
                        }
                        style={{ maxWidth: "900px" }}
                      />
                    )}
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .landing_button_text && (
                      <LyneUpLandingTopButton
                        className="sticy-lyneup-button"
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneup2BPageTranslation.nodes[0]
                              .landing_button_text,
                        }}
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}

        {data.allDirectusNewHomePageTranslation.nodes[0].result_section && (
          <ResultHomeSection
            data={
              data.allDirectusNewHomePageTranslation.nodes[0].result_section
            }
            page="lyneup"
          />
        )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusReviewTranslation.nodes && (
            <TrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              id={"review_section"}
            />
          )}

        {data.allDirectusNewHomePageTranslation.nodes[0]
          .testimonial_section && (
          <TestimonialHomeSection
            data={
              data.allDirectusNewHomePageTranslation.nodes[0]
                .testimonial_section
            }
            page="lyneup"
            buttonText={
              data.allDirectusLyneup2BPageTranslation.nodes[0]
                .landing_button_text
            }
          />
        )}

        {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
        intl.locale === "fr" &&
        data.allDirectusLyneupNewPageTranslation.nodes[0].cross_section ? (
          <CrossProductSection
            data={
              data.allDirectusLyneupNewPageTranslation.nodes[0].cross_section
            }
            price={data.allShopifyProduct.edges}
          />
        ) : (
          <LyneHomeCrossProductSection
            data={
              data.allDirectusLyneupNewPageTranslation.nodes[0]
                .product_cross_section
            }
            pageName="lyne_home"
          />
        )}

        <LyneHomeInnovationSection
          data={
            data.allDirectusLyneup2BPageTranslation.nodes[0].innovation_section
          }
        />

        {!IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupDesktopImageSection topRight="100px">
                <LyneupDesktopImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.desktop_image
                  }
                  alt={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.alt &&
                    intl.formatMessage({
                      id:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.alt,
                    })
                  }
                />
              </LyneupDesktopImageSection>
              <LyneupDesktopTextSection>
                <LyneupUserTextBox>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.title,
                    }}
                  />
                  <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.text,
                    }}
                  />
                  <EnterPriseSectionRedirect
                    to={`${
                      intl.locale === "fr"
                        ? "/dispositif-medical/#entreprise"
                        : "/scientific-study/#entreprise"
                    }`}
                  >
                    <LyneupDesktopTextImage
                      className="lazyload"
                      data-src={
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.logo_image
                      }
                    />
                  </EnterPriseSectionRedirect>
                </LyneupUserTextBox>
              </LyneupDesktopTextSection>
            </LyneupDesktopSection>
          )}
        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupExpertSection>
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .expert_section.mobile_image
                }
              />
              <LyneUpTextContainer>
                <LyneupUserTextBox>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.title,
                    }}
                  />
                  <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.text,
                    }}
                  />
                </LyneupUserTextBox>
              </LyneUpTextContainer>
            </LyneupExpertSection>
          )}
        {IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupExpertSection
              style={{ background: "#f0f0f0", padding: "5px 15px 10px 0px" }}
            >
              <EnterPriseSectionRedirect
                to={`${
                  intl.locale === "fr"
                    ? "/dispositif-medical/#entreprise"
                    : "/scientific-study/#entreprise"
                }`}
                style={{ margin: "0px" }}
              >
                <LyneupDesktopTextImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.logo_image
                  }
                  alt={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.alt &&
                    intl.formatMessage({
                      id:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.alt,
                    })
                  }
                />
              </EnterPriseSectionRedirect>
            </LyneupExpertSection>
          )}

        {data.allDirectusTvAdsSectionTranslation.nodes.length > 0 && (
          <TvAdsSection
            data={data.allDirectusTvAdsSectionTranslation.nodes[0]}
            page="home"
          />
        )}

        {process.env &&
        data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section
            }
            pageName="lyneup_gbp"
            id="faq"
          />
        ) : (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].product_faq_section
            }
            pageName="lyneup"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_bestseller_text
        product_images_section {
          default_image
          men {
            noir
            nude
          }
          women {
            noir
            nude
          }
        }
        product_material_info
        product_add_to_cart_button
        product_blue_banner_text
        product_result_section {
          section_title
          list {
            title
            mobile_title
            text
          }
        }
        product_why_choose_section {
          section_title
          mobile_section_title
          slider {
            image
            title
            text
          }
          text_list {
            image
            text
            mobile_text
          }
        }
        product_expert_section {
          section_title
          mobile_section_title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        product_satisfied_client_section {
          title
          mobile_title
          subtitle
          left_text {
            title
            text
          }
          right_text {
            title
            text
            link
            link_url
          }
        }
        product_video_testimonial {
          title
          subtitle
          testimonials {
            title
            author
            thumb
            video
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        product_companies_logo_slider {
          title
          subtitle
          list
        }
        size {
          item
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        male_size_chart {
          data {
            url
          }
        }
        female_size_chart {
          data {
            url
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        price_details
        size_popup_text
        language
        my_custom_size_text
        one_size_system_text
        morphology_text
        i_calculate_text
        most_suited_text
        edit_my_information_text
        return_text
        alma_popup_text
        testimonial_section {
          list {
            image
            text
            mobile_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        highlight_section {
          image
          title
          text
        }
        product_information_section {
          title
          list {
            image
            text
          }
        }
        technology_section {
          title
          text
          image
          mobile_image
          button_text
          button_url
        }
        medical_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        how_to_use_lyneup_section {
          title
          image
          mobile_image
          button_text
        }
        review_section {
          title
          text
          review_list {
            image
            name
            designation
            company
            city
            text
          }
          media_title
          media_list {
            image
            text
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        color_section {
          list {
            women_image
            men_image
            text
            value
          }
        }
        combo_section {
          single_product_text
          offer_title
          offer_text
        }
        combo_tooltip
      }
    }
    allDirectusLyneupNewPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_title
        lyneup_logo
        technology_section {
          title
          image
          list {
            image
            title
          }
        }
        testimonials_section {
          list {
            image
            text
          }
        }
        sedentary_section {
          title
          text
          image
        }
        action_section {
          title
          text
          list {
            text
            image
          }
        }
        virtous_circle_section {
          title
          image
        }
        benefits_section {
          title
          top_image
          bottom_image
          text
        }
        recommend_section {
          title
          list {
            image
            text
          }
        }
        experts_section {
          title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        medical_certificate_section {
          text
          image
        }
        zoom_section {
          title
          list {
            text
            image
          }
        }
        video_section {
          image
          title
        }
        logo_section {
          title
          list {
            image
            text
          }
        }
        lyneup_satisfied_section {
          title
          left_text
          right_text
        }
        lyneup_faq_title
        enterprise_section {
          title
          list {
            image
            text
            link_text
            link_url
          }
        }
        other_products {
          title
          list {
            image
            title
            logo
            subtitle
            text
            button_text
            button_url
          }
        }
        product_cross_section {
          title
          list {
            image
            title
            logo
            button_text
            button_url
            alt
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        color_section {
          list {
            women_image
            men_image
            text
            value
          }
        }
        size_selector_title
        calculate_size_text
        choose_size_text
        add_to_cart_button_text
        male_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text {
          men_title
          women_title
          subtitle
          men_image
          women_image
          left_text
          right_text
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusLyneup2BPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_title
        lyneup_logo
        testimonial_section {
          list {
            image
            text
          }
        }
        user_section {
          desktop_image
          mobile_image
          title
          text
          name
        }
        back_section {
          desktop_image
          mobile_image
          title
          text
          list {
            image
            title
          }
        }
        expert_section {
          desktop_image
          mobile_image
          title
          text
          logo_image
          alt
        }
        result_section {
          title
          text
          list {
            image
            title
          }
        }
        innovation_section {
          flag_image
          medical_image
          desktop_image
          mobile_image
          title
          text
        }
        action_section {
          title
          text
          desktop_image
          mobile_image
          button_text
          button_url
          alt
        }
        spine_section {
          title
          desktop_image
          mobile_image
        }
        circle_section {
          title
          desktop_image
          mobile_image
        }
        zoom_section {
          title
          image
          text
          list {
            image
            text
          }
        }
        landing_button_text
      }
    }
    allDirectusReviewTranslation(
      filter: { review: { product: { eq: "lyneup" } }, language: { eq: $lang } }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusNewHomePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        top_banner_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        top_banner_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        top_banner_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        top_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        lyneup_section_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        lyneup_section_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        lyneup_section_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        lyneup_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        product_section {
          title
          text
          product_list {
            top_text
            title
            logo_image
            image
            tag
            button_text
            button_url
          }
        }
        innovation_section {
          flag_image
          medical_logo
          list {
            desktop_image
            mobile_image
            title
            text
            button_text
            button_url
          }
        }
        review_section {
          desktop_image
          mobile_image
          title
          button_text
          button_url
        }
        testimonial_section {
          desktop_image
          mobile_image
          title
          text
          button_text
          button_url
          list {
            image
            text
          }
        }
        expert_section {
          desktop_image
          mobile_image
          title
          text
          bottom_text
          logo_image
          button_text
          button_url
        }
        result_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        instagram_section {
          title
          dekstop_image
          mobile_image
          instagram_logo
          instagram_text
          button_text
          button_url
          button_url_uk
        }
        video_section_text {
          title
          mobile_title
          button_text
          button_url
          medical_image
          innovation_image
        }
      }
    }
    allDirectusTvAdsSectionTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        video_title
        love_us_video_title
        video_text
        love_us_video_text
        video_url
        slider_title
        love_us_slider_title
        slider_text
        love_us_slider_text
        page_link_text
        slider {
          list {
            image
            text
            link
          }
        }
        slider_link_text
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        medicale_text
        medicale_logo {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        months_text {
          month
        }
        group_add_to_cart_title
        group_add_to_cart_save_text
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
        lyne_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "lyneup" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lyneup" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneUp))
